import React from 'react';
import {Button, Spinner} from 'reactstrap';

const SpinnerUp = (props) => {
    if(props.loading) return(
        <Button className="btn btn-custom-1 btn-sm" disabled >Processando <Spinner className="ml-1" size="sm" color="light"/></Button>
    )

    return (
        <Button className="btn btn-custom-1 btn-sm">Atualizar</Button>
    )
}

export default SpinnerUp;