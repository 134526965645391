import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/classes'
import { InputAdornment, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, FormControl, InputLabel, Select, Button, Box, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment';

import { toast } from 'react-toastify';

const Payments = (props) => {
    const [row, setRow] = useState(null);

    useEffect(() => {
        props.getRegistrations({ _id: props.match.params.id })
    }, []);

    const modalGenerate = (row) => {
        setRow(row)
    }

    const handleField = (e) => {
        setRow({ ...row, [e.target.name]: e.target.value })
    }

    const generate = async () => {
        await props.updateRegistration(row);
        const res = await props.generateInstallments({ id: row._id })
        if (res.error?.error) {
            toast.error(res.error.message)
        } else {
            await props.getRegistrations({ _id: props.match.params.id })
            toast.success(res.message)
            setRow(null)
        }

    }

    return (
        <div className="p-4">
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Turmas</h2>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Parcelas</TableCell>
                            <TableCell>Desconto</TableCell>
                            
                            <TableCell>Multa</TableCell>
                            <TableCell>Juros</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.registrations?.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row._id}
                                </TableCell>
                                <TableCell><b>{row?.student?.name}</b></TableCell>
                                <TableCell>R${row.totalValue}</TableCell>
                                <TableCell>{row.installments}</TableCell>
                                <TableCell>R${row.discount}</TableCell>
                                <TableCell>{row.fine}%</TableCell>
                                <TableCell>{row.interest}%</TableCell>
                                <TableCell align="right">{
                                    row.student.idAsaas ? (!row.installmentAsaas ? <Button variant='contained' size="sm" onClick={() => modalGenerate(row)} >Gerar</Button> :
                                        <Tooltip title="Pagamentos"><Link to={"/installments/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="material-symbols:payments" /></Link></Tooltip>) : <>Atualize o aluno!</>

                                }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal isOpen={row} >
                <ModalHeader toggle={() => setRow(null)}>{row?.student?.name}</ModalHeader>
                {row &&
                    <ModalBody>
                        <TextField InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    R$
                                </InputAdornment>
                            ),
                        }} name="totalValue" onChange={handleField} label="Valor Total" type="number" value={row.totalValue} size='small' fullWidth sx={{ mb: 2 }} />
                        <TextField name="installments" onChange={handleField} label="Parcelas" type="number" value={row.installments} size='small' fullWidth sx={{ mb: 2 }} />

                        <TextField
                            name="discount"
                            onChange={handleField}
                            label="Desconto"
                            type="number"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        R$
                                    </InputAdornment>
                                ),
                            }}
                            value={row.discount}
                            size="small"
                            fullWidth
                            sx={{ mb: 2 }}
                        />
                        <TextField InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            ),
                        }} name="fine" label="Multa" onChange={handleField} type="number" value={row.fine} size='small' fullWidth sx={{ mb: 2 }} />
                        <TextField InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            ),
                        }} name="interest" label="Juros" onChange={handleField} type="number" value={row.interest} size='small' fullWidth sx={{ mb: 2 }} />
                        <TextField name="date" label="Data primeiro vencimento" onChange={handleField} type="date" value={row.date} size='small' fullWidth />
                    </ModalBody>
                }
                <ModalFooter>
                    <Button style={{ marginRight: '15px' }} size="sm" onClick={generate} variant='contained'>Gerar</Button>
                    <Button size="sm" color="secondary" onClick={() => setRow(null)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>

    )
}


const mapStateToProps = state => ({
    registrations: state.class.registrations,
    class: state.class.classDetails
})

export default connect(mapStateToProps, actions)(Payments);

