import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/courses'
import { Pagination, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment';

import { toast } from 'react-toastify';

const Course = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const location = props?.location?.search;
    const query = new URLSearchParams(location).get('query');

    useEffect(() => {
        props.getCourses({ pageCurrent, limit, query })
    }, [pageCurrent, location]);

    const deleteCourse = async () => {
        setLoading(true)
        const result = await props.deleteCourse(idDelete)
        await props.getCourses({ pageCurrent, limit, query })
        toast.success(result.message);
        setLoading(false)
        toggleModal();
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let courses = [];

    if (props.courses) {
        courses = props.courses.docs
    }

    return (
        <div className="p-4">
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Cursos</h2>
                </div>
                <Link to={"/add-course"}>
                    <Button style={{ marginRight: '20px' }} className="btn btn-custom-1 btn-sm">Cadastrar</Button>
                </Link>
            </div>
            <TableContainer component={Paper} id='table-container'>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Nome</TableCell>
                            <TableCell>Início</TableCell>
                            <TableCell>Fim</TableCell>
                            <TableCell>Valor</TableCell>
                            <TableCell>Inscritos</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courses.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell><b>{row.name}</b></TableCell>
                                <TableCell>{moment(row.start).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{row.end ? moment(row.end).format('DD/MM/YYYY') : '-'}</TableCell>
                                <TableCell>R${row.value?.toFixed(2)}</TableCell>
                                <TableCell>{row.totalSubscribers}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Inscritos"><Link to={"/course-subs/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="carbon:user-follow" /></Link></Tooltip>
                                    <Tooltip title="Editar"><Link to={"/update-course/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="material-symbols:edit" /></Link></Tooltip>
                                    <Tooltip title="Excluir"><Icon onClick={() => toggleModal(row._id)} width="25" icon="material-symbols:delete" /></Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {!props.courses ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.courses.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            }

            <Modal isOpen={openModal} >
                <ModalHeader toggle={() => toggleModal()}>Apagar</ModalHeader>
                <ModalBody>
                    Você tem certeza que deseja apagar?
                </ModalBody>
                <ModalFooter>
                    <span onClick={() => deleteCourse()}>
                        <SpinnerDelete text={true} loading={loading} />
                    </span>
                    <Button size="sm" color="secondary" onClick={() => toggleModal()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    courses: state.course.courses,
    course: state.auth.course
})

export default connect(mapStateToProps, actions)(Course);

