const students_reduce = (state = {}, actions) => {
    switch(actions.type){
        case "GET_STUDENTS":
            return{
                ...state,
                students: actions.payload.data
            }
        case "GET_ALL_STUDENTS":
            return{
                ...state,
                studentsAll: actions.payload.data
            }
        case "GET_STUDENT":
            return{
                ...state,
                studentDetails: actions.payload.data
            }
        case "CLEAR_STUDENT":
            return{
                ...state,
                studentDetails: null
            }
        case "CLEAR_STUDENTS":
            return{
                ...state,
                students: null
            }
        default: 
            return state;
    }
}

export default students_reduce;