import React from 'react'
const AlertSuccess = (props) => {
    if(props.error.message === "" || props.error === "") return null

    return (
        <div>
            <div className="alert alert-success" color="success">
                {props.error.message}
            </div>
            
        </div>        
    )
}

export default AlertSuccess;