import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'
import {queryString} from '../../utils/String'

export const getStudents = (params) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/students?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_STUDENTS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getAllStudents = (params) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/students-all?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_ALL_STUDENTS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteStudent = (_id) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/students/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewStudent = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/students/${id}`, getHeaders());
        dispatch({ type: 'GET_STUDENT', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const getStudentByCPF = (cpf) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/students-by-cpf/${cpf}`, getHeaders());
        dispatch({ type: 'GET_STUDENT', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddStudent = (props) => async () => {
    try {
        const response = await axios.post(api+"/students", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateStudent = (props) => async () => {
    try {
        const response = await axios.put(api+"/students/"+props._id, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearStudent = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_STUDENT"})
    } 
}

export const clearStudents = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_STUDENTS"})
    } 
}





