import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Label, Input, UncontrolledButtonDropdown,  DropdownToggle,DropdownMenu } from 'reactstrap';
import {connect} from 'react-redux'
import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import SpinnerUp from '../../components/SpinnerUp'
import * as actions from '../../store/actions/users'

class UpdateUserPass extends Component{

    state = {
        password: "",
        error: "",
        success: "",
        loading: false,
    }

    componentDidMount(){
        const {id} = this.props.match.params
        this.props.getViewUser(id)
    } 

    componentDidUpdate(nextProps){
        const {id} = this.props.match.params
        if(!this.props.user && nextProps.user) this.props.getViewUser(id)
    }

    componentWillUnmount(){
        this.props.clearUser()
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    validate(){
        const {password} = this.state;
        if(!password){
            return this.setState({error: {message:  "Informe a senha!"}})
        }else if(password.length < 6){
            return this.setState({error: {message:  "A senha precisa ter no mínimo 6 caracteres!"}})
        }

        return true;
    }

    

    handleUpdate(){
        const {password} = this.state;
        const _id = this.props.match.params.id
        if(!this.validate()) return
        
        this.setState({loading: true});

        this.props.handleUpdateUser({password, _id}, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})                
                this.setState({success: ""});
            }else{
                this.setState({error: ""});
                this.setState({success: {message: msg.error.message}});
                this.setState({formSuccess: true});
            }
            
            this.setState({loading: false});
        });

    }

    render(){
        const {password, loading, error, success} = this.state
        return(
            <div className='p-4'>
                <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Editar Senha - {this.props.userDetails ? this.props.userDetails.name : "Carregando..."}</h2>
                </div>
                <span className="d-none d-md-block">
                    <Link to={"/user"}>
                        <button className="btn btn-info btn-sm">Listar</button>
                    </Link>
                    <Link to={"/view-user/" + this.props.match.params.id}>
                        <button className="ml-1 btn btn-primary btn-sm">Visualizar</button>
                    </Link>
                </span>
                <div className="dropdown d-block d-md-none">
                    <UncontrolledButtonDropdown >
                        <DropdownToggle color="primary" size="sm" caret>
                            Ações
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link className="dropdown-item" to={"/user"}> Listar</Link>
                            <Link className="dropdown-item" to={"/view-user/" + this.props.match.params.id}> Visualizar</Link>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div>
            <hr />
            <AlertDanger error={error}/>
                <AlertSuccess error={success}/>
            <Form>
                    <FormGroup>
                        <Label for="password">Nova Senha</Label>
                        <Input type="password" value={password} name="password" id="password" placeholder="Digite a nova senha!" autoComplete="password" onChange={(ev) => this.onChangeInput(ev)}/>  
                    </FormGroup>
                    <Link onClick={() => this.handleUpdate()} to="#">
                        <SpinnerUp loading={loading}></SpinnerUp>
                    </Link>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.userDetails
})

export default connect(mapStateToProps, actions)(UpdateUserPass);