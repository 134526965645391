import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/users'
import { Pagination, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, FormControl, InputLabel, Select, Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';

import { toast } from 'react-toastify';

const User = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [role, setRole] = useState("all");
    useEffect(() => {
        props.getUsers({pageCurrent, limit, role})
    }, [pageCurrent, role]);

    const deleteUser = async () => {
        setLoading(true)
        const result = await props.deleteUser(idDelete)
        await props.getUsers(pageCurrent, limit)
        toast.success(result.message);
        setLoading(false)
        toggleModal();
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let users = [];

    if (props.users) {
        users = props.users.docs
    }

    return (
        <div class="p-4">
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Usuários</h2>
                </div>
                <Link to={"/add-user"}>
                    <Button className="btn btn-custom-1 btn-sm">Cadastrar</Button>
                </Link>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row._id}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Editar"><Link to={"/update-user/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="material-symbols:edit" /></Link></Tooltip>
                                    <Tooltip title="Excluir"><Link onClick={() => toggleModal(row._id)}><Icon width="25" icon="material-symbols:delete" /></Link></Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {!props.users ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.users.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            }

            <Modal isOpen={openModal} >
                <ModalHeader toggle={() => toggleModal()}>Apagar Usuário</ModalHeader>
                <ModalBody>
                    Você tem certeza que deseja apagar o usuário?
                </ModalBody>
                <ModalFooter>
                    <span onClick={() => deleteUser()}>
                        <SpinnerDelete text={true} loading={loading} />
                    </span>
                    <Button size="sm" color="secondary" onClick={() => toggleModal()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    users: state.user.users,
    user: state.auth.user
})

export default connect(mapStateToProps, actions)(User);

