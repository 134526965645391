import axios from 'axios';
import { api } from '../../config'
import { getHeaders } from './localStorage'
import errorHandling from './errorHandling'
import { queryString } from '../../utils/String'

export const getClasses = (params) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/classes?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_CLASSES", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteClass = (_id) => async (dispatch) => {
    try {
        const response = await axios.delete(api + "/classes/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewClass = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/classes/${id}`, getHeaders());
        dispatch({ type: 'GET_CLASS', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddClass = (props) => async () => {
    try {
        const response = await axios.post(api + "/classes", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateClass = (props) => async () => {
    try {
        const response = await axios.put(api + "/classes/" + props._id, props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const clearClass = () => {
    return function (dispatch) {
        dispatch({ type: "CLEAR_CLASS" })
    }
}

export const clearClasses = () => {
    return function (dispatch) {
        dispatch({ type: "CLEAR_CLASSES" })
    }
}

export const getRegistrations = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/registrations/" + props._id, getHeaders());

        dispatch({ type: 'GET_REGISTRATIONS', payload: response.data })
    } catch (err) {
        return errorHandling(err);
    }
}

export const getRegistration = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/registrations-view/" + props._id, getHeaders());

        dispatch({ type: 'GET_REGISTRATION', payload: response.data })
    } catch (err) {
        return errorHandling(err);
    }
}

export const updateRegistration = (props) => async (dispatch) => {
    try {
        const response = await axios.put(api + "/registrations/" + props._id, props, getHeaders());

        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}


export const addRegistration = (props) => async (dispatch) => {
    try {
        const response = await axios.post(api + "/registrations/", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const generateInstallments = (props) => async (dispatch) => {
    try {
        const response = await axios.post(api + "/registrations/generatePayment", props, getHeaders());

        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getInstallments = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/registrations/payment/" + props._id, getHeaders());

        dispatch({ type: 'GET_INSTALLMENTS', payload: response.data })
    } catch (err) {
        return errorHandling(err);
    }
}



export const deleteRegistration = (props) => async (dispatch) => {
    try {
        const response = await axios.delete(api + "/registrations/" + props.class + "/" + props.student, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const generateRandomPayment = (props) => async (dispatch) => {
    try {
        const response = await axios.post(api + "/registrations/generateRandomPayment", props, getHeaders());

        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getRandomPayment = (props) => async (dispatch) => {
    try {
        const response = await axios.get(api + "/registrations/random-payment/" + props._id, getHeaders());

        dispatch({ type: 'GET_RANDOM_PAYMENTS', payload: response.data })
    } catch (err) {
        return errorHandling(err);
    }
}





