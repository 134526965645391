import { useEffect, useReducer, useState } from "react";
import { roles } from "../../config";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getViewUser, handleAddUser, handleUpdateUser } from '../../store/actions/users'
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField, Select, FormControl, InputLabel, Autocomplete } from '@mui/material';

const AddUser = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState({});
  const [spec, setSpec] = useState([]);
  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório.'),
    email: Yup.string().email("Informe um e-mail válido.").required('E-mail é obrigatório.'),
    password: !isEdit ? Yup.string().min(6, "A senha deve ter no mínimo 6 caracteres.").required('Nome é obrigatório.') : ""
  });

  useEffect(() => {
    if (props.match.params.id) {
      setIsEdit(true);
      props.getViewUser(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    setSpec(props.customers?.docs)
  }, [props.customers]);

  useEffect(() => {
    if (props.match.params.id) {
      setUser(props.user);
    }
  }, [props.user]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
      password: ''
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (isEdit) {
          values._id = props.match.params.id;
          delete values.password;
        }
        const result = isEdit ? await props.updateUser(values) : await props.addUser(values);
        if (result.error) {
          setSubmitting(false);
          toast.error(result.error.message);
        } else {
          if (!isEdit) resetForm();
          toast.success(result.message);
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        toast.error(error.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <div className="p-4">
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="titulo">{isEdit ? "Editar" : "Cadastrar"} Usuário</h2>
        </div>
        <Link to={props.match.params.role ? `/user/${props.match.params.role}/${props.match.params.store}` : "/user"}>
          <div className="p-2">

            <button className="btn btn-outline-info btn-sm">
              Listar
            </button>
          </div>
        </Link>
      </div>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label={'Nome'}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="email"
                      label={'E-mail'}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                  {isEdit ? "" :
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <TextField
                        fullWidth
                        type="password"
                        label={'Senha'}
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Stack>}
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Salvar</LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider >
    </div>
  )
}



const mapStateToProps = state => {
  return ({
    user: state.user.userDetails
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data) => dispatch(handleAddUser(data)),
    getViewUser: (id) => dispatch(getViewUser(id)),
    updateUser: (data) => dispatch(handleUpdateUser(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);