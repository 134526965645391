import { useEffect, useReducer, useState } from "react";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getViewStudent, handleAddStudent, handleUpdateStudent } from '../../store/actions/students'
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField, Select, FormControl, InputLabel, Autocomplete } from '@mui/material';

const AddStudent = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [student, setStudent] = useState({});
  const [spec, setSpec] = useState([]);
  const NewStudentSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório.'),
    email: Yup.string().email("Informe um e-mail válido.").required('E-mail é obrigatório.'),
    cpfCnpj: Yup.string().required('CPF/CNPJ é obrigatório.'),
    mobilePhone: Yup.string().required('Celular é obrigatório.'),
  });

  useEffect(() => {
    if (props.match.params.id) {
      setIsEdit(true);
      props.getViewStudent(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.match.params.id) {
      setStudent(props.student);
    }
  }, [props.student]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: student?.name || '',
      email: student?.email || '',
      mobilePhone: student?.mobilePhone || '',
      cpfCnpj: student?.cpfCnpj || '',
    },
    validationSchema: NewStudentSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (isEdit) {
          values._id = props.match.params.id;
          delete values.password;
        }
        const result = isEdit ? await props.updateStudent(values) : await props.addStudent(values);
        if (result.error) {
          setSubmitting(false);
          toast.error(result.error.message);
        } else {
          if (!isEdit) resetForm();
          toast.success(result.message);
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        toast.error(error.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <div className="p-4">
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="titulo">{isEdit ? "Editar" : "Cadastrar"} Aluno</h2>
        </div>
        <Link to={"/students"}>
          <div className="p-2">

            <button className="btn btn-outline-info btn-sm">
              Listar
            </button>
          </div>
        </Link>
      </div>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label={'Nome'}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="email"
                      label={'E-mail'}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="text"
                      label={'Celular'}
                      {...getFieldProps('mobilePhone')}
                      error={Boolean(touched.mobilePhone && errors.mobilePhone)}
                      helperText={touched.mobilePhone && errors.mobilePhone}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="text"
                      label={'CPF/CNPJ'}
                      {...getFieldProps('cpfCnpj')}
                      error={Boolean(touched.cpfCnpj && errors.cpfCnpj)}
                      helperText="Somente dígitos, exemplo: 09524976978"
                    />
                  </Stack>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Salvar</LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider >
    </div>
  )
}



const mapStateToProps = state => {
  return ({
    student: state.student.studentDetails
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    addStudent: (data) => dispatch(handleAddStudent(data)),
    getViewStudent: (id) => dispatch(getViewStudent(id)),
    updateStudent: (data) => dispatch(handleUpdateStudent(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);