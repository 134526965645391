import React, {Component} from 'react';
import {Form, FormGroup, Input, Label} from 'reactstrap'
import {Link} from 'react-router-dom'

import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import SpinnerLogin from '../../components/SpinnerLogin'

import {connect} from 'react-redux'
import * as actions from '../../store/actions'

class Login extends Component{

    state = {
        email: "",
        password: "",
        error: "",
        loading: false,
        msg: ""
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg });
            this.props.location.state.msg = "";
        }
    }

    handleLogin(){
        const {email, password} = this.state;

        if(!this.validate()) return
        this.setState({loading: true});
        this.setState({msg: ""});
        this.props.handleLogin({email, password}, (msg) => {
            if(msg.error){
                this.setState({error: {message: msg.error.message}})
                this.setState({loading: false});
            }
            
        });
    }

    validate(){
        const {email, password} = this.state;
        if(!email){
            this.setState({error: {message: "Informe seu e-mail!"}})
            return false;
        }else if(!password){
            this.setState({error: {message:  "Informe sua senha!"}})
            return false;
        }

        return true;
    }

    render(){        
        const { loading, email, password, error, msg } = this.state;
        return (
            <>     
            <div className="container-login">
                <div className="login card shadow">
                    <Form className="form-signin text-center">
                        <AlertDanger error={error}/>
                        {msg ? <AlertSuccess error={{ message: msg }} /> : ""}
                        <FormGroup>
                            <Label for="email">E-mail</Label>
                            <Input type="email" value={email} name="email" autoComplete="email" id="email" placeholder="Digite seu e-mail" onChange={(ev) => this.onChangeInput(ev)}/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="password">Senha</Label>
                            <Input type="password" value={password} name="password" autoComplete="password" id="password" placeholder="Digite sua senha" onChange={(ev) => this.onChangeInput(ev)}/>
                        </FormGroup>
                        
                        <span onClick={() => this.handleLogin()}><SpinnerLogin loading={loading}/></span>
                        
                    </Form>
                </div>
            </div>
            </>
        )
    }
    
}

export default connect(null, actions)(Login);