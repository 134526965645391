import React, { useState } from 'react';
import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import { Container } from '@mui/material';
import '../../styles/dashboard/index.css'

const BaseDashboard = (props) => {
    const [sitMenu, setSitMenu] = useState(true);
    const altSitMenu = () => {
        setSitMenu(!sitMenu)
    }
    return (
        <>
            <div className="d-flex">
                <Sidebar active={sitMenu} handleLogout={props.handleLogout} />
                <div style={{ width: '100%' }}>

                    <Header handleLogout={props?.handleLogout} altSitMenu={altSitMenu} dataUser={props} />
                    {{...props.children}}
                </div>
            </div>
        </>
    )

}

export default connect(null, actions)(BaseDashboard);