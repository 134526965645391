import axios from 'axios';
import {api} from '../../config'
import {getHeaders} from './localStorage'
import errorHandling from './errorHandling'
import {queryString} from '../../utils/String'

export const getUsers = (params) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/users?${queryString(params)}`, getHeaders());
        dispatch({ type: "GET_USERS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const getUsersSpecific = (spec, id, pageCurrent, limit) => async (dispatch) => {
    try {
        const response = await axios.get(api + `/users/${spec}/${id}/?page=${pageCurrent}&limit=${limit}`, getHeaders());
        dispatch({ type: "GET_USERS", payload: response.data });
    } catch (err) {
        return errorHandling(err);
    }
}

export const deleteUser = (_id, users) => async (dispatch) => {
    try {
        const response = await axios.delete(api+"/users/" + _id, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const getViewUser = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`${api}/users/${id}`, getHeaders());
        dispatch({ type: 'GET_USER', payload: response.data })
    } catch (error) {
        return errorHandling(error);
    }
}

export const handleAddUser = (props) => async () => {
    try {
        const response = await axios.post(api+"/users", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdateUser = (props) => async () => {
    try {
        const response = await axios.put(api+"/users", props, getHeaders());
        return response.data;
    } catch (err) {
        return errorHandling(err);
    }
}

export const handleUpdatePerfil = (props, callback) => {
    return function(dispatch){
        axios.put(api+"/perfil", props, getHeaders()).then((response) => {
            callback({error: response.data});
        }).catch((err) => callback(errorHandling(err)))
    }
}


export const clearUser = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_USER"})
    } 
}

export const clearUsers = () => {
    return function(dispatch){
        dispatch({type: "CLEAR_USERS"})
    } 
}

export const viewPerfil = () => {
    return function(dispatch){
            axios.get(api+"/perfil", getHeaders()).then((response) => {
                dispatch({type: "LOGIN_USER", payload: response.data})
            }).catch((err) => {
                dispatch({type: "LOGOUT_USER"})
            })
        }
}





