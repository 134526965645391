import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { getAllStudents } from '../../store/actions/students'
import { getViewClass } from '../../store/actions/classes'
import { getRegistrations, addRegistration, deleteRegistration } from '../../store/actions/classes'
import SearchAndEnroll from "../../components/SearchAndEnroll";

const Registration = (props) => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    props.getAllStudents({ pageCurrent: 1, limit: 1000 })
    props.getRegistrations({_id: props.match.params.id})
    props.getViewClass(props.match.params.id)
  }, []);

  useEffect(() => {
    let stu = props.students?.map(elem => {
      return {
        ...elem,
        enrolled: props.registration?.findIndex(reg => reg.student._id.toString() === elem._id) > -1
      }
    })
    setStudents(stu || []);
  }, [props.students, props.registration]);

  const enrollStudent = async (id) => {
    await props.addRegistration({class: props.match.params.id, student: id});
    setStudents(
      students.map((student) =>
        student._id === id ? { ...student, enrolled: true } : student
      )
    );
  };

  const unenrollStudent = async (id) => {
    await props.deleteRegistration({class: props.match.params.id, student: id});
    setStudents(
      students.map((student) =>
        student._id === id ? { ...student, enrolled: false } : student
      )
    );
  };
  return (
    <div className="p-4">
      <h3>Alunos - {props.class?.name}</h3>
      <br />
      <SearchAndEnroll
        students={students}
        enrollStudent={enrollStudent}
        unenrollStudent={unenrollStudent}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return ({
    students: state.student.studentsAll,
    registration: state.class.registrations,
    class: state.class.classDetails
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllStudents: (params) => dispatch(getAllStudents(params)),
    getRegistrations: (params) => dispatch(getRegistrations(params)),
    addRegistration: (params) => dispatch(addRegistration(params)),
    deleteRegistration: (params) => dispatch(deleteRegistration(params)),
    getViewClass: (params) => dispatch(getViewClass(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
