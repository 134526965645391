import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Form, FormGroup, Label, Input } from 'reactstrap';
import {connect} from 'react-redux'
import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import SpinnerUp from '../../components/SpinnerUp'
import * as actions from '../../store/actions/users'
import validator from 'validator'

class UpdatePerfil extends Component{

    state = {
        _id: "",
        name: "",
        email: "",
        error: "",
        success: "",
        loading: false,
    }

    componentDidMount(){
        const {name, email, _id} = this.props.user;
        this.setState({name})
        this.setState({email})
        this.setState({_id})
    }

    onChangeInput = (ev) => {
        this.setState({[ev.target.name]: ev.target.value})
    }

    validate(){
        this.setState({success: ""});
        const {name, email} = this.state;
        if(!name){
            return this.setState({error: {message: "Informe o nome!"}})
        }else if(!email){
            return this.setState({error: {message: "Informe o e-mail!"}})            
        }else if(!validator.isEmail(email)){
            return this.setState({error: {message:  "E-mail inválido!"}})            
        }

        return true;
    }

    handleUpdate(){
        const {name, email, _id} = this.state;

        if(!this.validate()) return
        
        this.setState({loading: true});

        this.props.handleUpdatePerfil({name, email, _id}, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})                
                this.setState({success: ""});
            }else{
                this.setState({error: ""});
                this.setState({success: {message: msg.error.message}});
                this.updateDataUser()
            }
            
            this.setState({loading: false});
        });

    }

    async updateDataUser(){
        await this.props.viewPerfil(() => {
            this.setState({loading: false})
        })
    }

    render(){
        const {name, email, loading, error, success} = this.state
        return(
            <div className='p-4'>
                <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Editar Perfil</h2>
                </div>
                <Link to={"/perfil/"}>
                    <button className="ml-1 btn btn-primary btn-sm">Perfil</button>
                </Link>
            </div>
            <hr />
            <AlertDanger error={error}/>
                <AlertSuccess error={success}/>
            <Form>
                    <FormGroup>
                        <Label for="name">Nome</Label>
                        <Input type="text" value={name} name="name" id="name" placeholder="Digite o nome completo" autoComplete="name" onChange={(ev) => this.onChangeInput(ev)}/>  
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">E-mail</Label>
                        <Input type="email" value={email} name="email" id="email" placeholder="Digite o e-mail" autoComplete="email" onChange={(ev) => this.onChangeInput(ev)}/>  
                    </FormGroup>
                    <Link onClick={() => this.handleUpdate()} to="#">
                        <SpinnerUp loading={loading}></SpinnerUp>
                    </Link>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userDetails: state.user.userDetails
})

export default connect(mapStateToProps, actions)(UpdatePerfil);