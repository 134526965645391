export const getStatus = (status) => {
    const translates = {
        PENDING: "Pendente",
        RECEIVED: "Recebido",
        CONFIRMED: "Confirmado",
        OVERDUE: "Atrasado",
        REFUNDED: "Reembolsado",
        RECEIVED_IN_CASH: "Recebido em dinheiro",
        REFUND_REQUESTED: "Solicitação de reembolso",
        REFUND_IN_PROGRESS: "Reembolso em andamento",
        CHARGEBACK_REQUESTED: "Solicitação de chargeback",
        CHARGEBACK_DISPUTE: "Disputa de chargeback",
        AWAITING_CHARGEBACK_REVERSAL: "Aguardando reversão de chargeback",
        DUNNING_REQUESTED: "Solicitação de cobrança",
        DUNNING_RECEIVED: "Cobrança recebida",
        AWAITING_RISK_ANALYSIS: "Aguardando análise de risco"
    };

    return translates[status] || status;
}