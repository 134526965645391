import { useEffect, useReducer, useState } from "react";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getViewClass, handleAddClass, handleUpdateClass } from '../../store/actions/classes'
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField, Select, FormControl, InputLabel, Autocomplete } from '@mui/material';

const AddClass = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [classExist, setClass] = useState({});
  const [spec, setSpec] = useState([]);
  const NewClassSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório.'),
    start: Yup.string().required('Data de início é obrigatório.'),
    end: Yup.string().required('Data de fim é obrigatório.'),
    totalValue: Yup.string().required('Valor é obrigatório.'),
    installments: Yup.string().required('Parcelas é obrigatório.'),
  });

  useEffect(() => {
    if (props.match.params.id) {
      setIsEdit(true);
      props.getViewClass(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.match.params.id) {
      setClass(props.class);
    }
  }, [props.class]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: classExist?.name || '',
      start: classExist?.start?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      end: classExist?.end?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      totalValue: classExist?.totalValue || '',
      installments: classExist?.installments || '',
    },
    validationSchema: NewClassSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (isEdit) {
          values._id = props.match.params.id;
          delete values.password;
        }
        const result = isEdit ? await props.updateClass(values) : await props.addClass(values);
        if (result.error) {
          setSubmitting(false);
          toast.error(result.error.message);
        } else {
          if (!isEdit) resetForm();
          toast.success(result.message);
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        toast.error(error.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <div className="p-4">
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="titulo">{isEdit ? "Editar" : "Cadastrar"} Turma</h2>
        </div>
        <Link to={props.match.params.role ? `/class/${props.match.params.role}/${props.match.params.store}` : "/class"}>
          <div className="p-2">

            <button className="btn btn-outline-info btn-sm">
              Listar
            </button>
          </div>
        </Link>
      </div>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label={'Nome'}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="date"
                      label={'Data de início'}
                      {...getFieldProps('start')}
                      error={Boolean(touched.start && errors.start)}
                      helperText={touched.start && errors.start}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="date"
                      label={'Data de fim'}
                      {...getFieldProps('end')}
                      error={Boolean(touched.end && errors.end)}
                      helperText={touched.end && errors.end}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="number"
                      label={'Valor total'}
                      {...getFieldProps('totalValue')}
                      error={Boolean(touched.totalValue && errors.totalValue)}
                      helperText={touched.totalValue && errors.totalValue}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="number"
                      label={'Parcelas'}
                      {...getFieldProps('installments')}
                      error={Boolean(touched.installments && errors.installments)}
                      helperText={touched.installments && errors.installments}
                    />
                  </Stack>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Salvar</LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider >
    </div>
  )
}



const mapStateToProps = state => {
  return ({
    class: state.class.classDetails
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    addClass: (data) => dispatch(handleAddClass(data)),
    getViewClass: (id) => dispatch(getViewClass(id)),
    updateClass: (data) => dispatch(handleUpdateClass(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClass);