import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/classes'
import { Pagination, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, FormControl, InputLabel, Select, Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment';

import { toast } from 'react-toastify';

const Class = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [ openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [role, setRole] = useState("all");
    useEffect(() => {
        props.getClasses({pageCurrent, limit, role})
    }, [pageCurrent, role]);

    const deleteClass = async () => {
        setLoading(true)
        const result = await props.deleteClass(idDelete)
        await props.getClasses(pageCurrent, limit)
        toast.success(result.message);
        setLoading(false)
        toggleModal();
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let classes = [];

    if (props.classes) {
        classes = props.classes.docs
    }

    return (
        <div className="p-4">
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Turmas</h2>
                </div>
                <Link to={"/add-class"}>
                    <Button className="btn btn-custom-1 btn-sm">Cadastrar</Button>
                </Link>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>Início</TableCell>
                            <TableCell>Fim</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classes.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row._id}
                                </TableCell>
                                <TableCell><b>{row.name}</b></TableCell>
                                
                                <TableCell>{moment(row.start).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{moment(row.end).format('DD/MM/YYYY')}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Pagamentos"><Link to={"/payments/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="material-symbols:payments" /></Link></Tooltip>
                                    <Tooltip title="Matrículas"><Link to={"/registration/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="mdi:account-school" /></Link></Tooltip>
                                    <Tooltip title="Editar"><Link to={"/update-class/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="material-symbols:edit" /></Link></Tooltip>
                                    <Tooltip title="Excluir"><Icon onClick={() => toggleModal(row._id)} width="25" icon="material-symbols:delete" /></Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {!props.classes ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.classes.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            }

            <Modal isOpen={openModal} >
                <ModalHeader toggle={() => toggleModal()}>Apagar Usuário</ModalHeader>
                <ModalBody>
                    Você tem certeza que deseja apagar o usuário?
                </ModalBody>
                <ModalFooter>
                    <span onClick={() => deleteClass()}>
                        <SpinnerDelete text={true} loading={loading} />
                    </span>
                    <Button size="sm" color="secondary" onClick={() => toggleModal()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    classes: state.class.classes,
    class: state.auth.class
})

export default connect(mapStateToProps, actions)(Class);

