import React from 'react'
const AlertDanger = (props) => {
    if(props.error === "") return null

    return (
        <div>
            <div className="alert alert-danger" color="danger">
                {props.error.message}
            </div>
            
        </div>        
    )
}

export default AlertDanger;