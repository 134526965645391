import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/students'
import { TextField, Pagination, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, FormControl, InputLabel, Select, Button, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import { toast } from 'react-toastify';
import debounce from 'lodash.debounce';
const Student = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 10;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [search, setSearch] = useState("");
    useEffect(() => {
        props.getStudents({ pageCurrent, limit, search })
    }, [pageCurrent]);


    const debouncedSearch = useCallback(
        debounce(async (s) => {
            props.getStudents({ pageCurrent, limit, search: s })
        }, 1000),
        []
    );

    const deleteStudent = async () => {
        setLoading(true)
        const result = await props.deleteStudent(idDelete)
        await props.getStudents(pageCurrent, limit, search)
        toast.success(result.message);
        setLoading(false)
        toggleModal();
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let students = [];

    if (props.students) {
        students = props.students.docs
    }

    return (
        <div className="p-4">
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Alunos</h2>
                </div>
                <Link to={"/add-student"}>
                    <Button className="btn btn-custom-1 btn-sm">Cadastrar</Button>
                </Link>
            </div>

            <FormControl variant="standard" sx={{ marginRight: '20px', marginBottom: '10px' }} size='small'>
                <TextField value={search} label='Pesquisar' onChange={e => {
                    setSearch(e.target.value); debouncedSearch(e.target.value)
                }} />
            </FormControl>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Celular</TableCell>
                            <TableCell>CPF</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {students.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    {row._id}
                                </TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.mobilePhone}</TableCell>

                                <TableCell>{row.cpfCnpj}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="Editar"><Link to={"/update-student/" + row._id}><Icon width="25" style={{ marginRight: '5px' }} icon="material-symbols:edit" /></Link></Tooltip>
                                    <Tooltip title="Excluir"><Icon onClick={() => toggleModal(row._id)} width="25" icon="material-symbols:delete" /></Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {!props.students ? "" :
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.students.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                </Box>
            }

            <Modal isOpen={openModal} >
                <ModalHeader toggle={() => toggleModal()}>Apagar Usuário</ModalHeader>
                <ModalBody>
                    Você tem certeza que deseja apagar o usuário?
                </ModalBody>
                <ModalFooter>
                    <span onClick={() => deleteStudent()}>
                        <SpinnerDelete text={true} loading={loading} />
                    </span>
                    <Button size="sm" color="secondary" onClick={() => toggleModal()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


const mapStateToProps = state => ({
    students: state.student.students
})

export default connect(mapStateToProps, actions)(Student);

