import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import * as actions from '../../store/actions'
import { Link } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Loading  from '../../components/Loading'
import { api } from '../../config'


library.add(fas)

const Perfil = (props) => {
    const [user, setUser] = useState()
    useEffect(() => {
        const { user } = props;
        setUser(user);

    }, [props.user]);

    if (!user) return (<Loading />)

    return (
        <div className='p-4'>
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Perfil</h2>
                </div>

                <span className="d-none d-md-block">
                    <Link to={"/update-perfil"}>
                        <button className="btn btn-info btn-sm mr-1">Editar</button>
                    </Link>
                    <Link to={"/update-perfil-pass"}>
                        <button className="btn btn-info btn-sm">Editar Senha</button>
                    </Link>
                </span>
                <div className="dropdown d-block d-md-none">
                    <UncontrolledButtonDropdown >
                        <DropdownToggle color="primary" size="sm" caret>
                            Ações
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link className="dropdown-item" to={"/update-perfil"}> Editar</Link>
                            <Link className="dropdown-item" to={"/update-perfil-pass"}> Editar Senha</Link>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>



            </div><hr />
            <dl className="row">
                <dt className="col-sm-3">Foto</dt>
                <dd className="col-sm-9">
                    <div className="img-perfil">
                        <img height="150" className="rounded-circle" src={api + user.url} alt={user.name} />
                        <div className="edit">
                            <Link to={"/update-perfil-photo"}>
                                <button className="btn btn-primary btn-sm"><FontAwesomeIcon icon="edit" /></button>
                            </Link>
                        </div>
                    </div>
                </dd>

                <dt className="col-sm-3">Nome</dt>
                <dd className="col-sm-9">{user.name}</dd>

                <dt className="col-sm-3">E-mail</dt>
                <dd className="col-sm-9">{user.email}</dd>

            </dl>
        </div>
    )
}


const mapStateToProps = state =>
({
    user: state.auth.user
})


export default connect(mapStateToProps, actions)(Perfil);