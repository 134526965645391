import {combineReducers} from 'redux';
import authReducer from './auth_reducer'
import userReducer from './users_reducer'
import studentReducer from './students_reducer'
import classesReducer from './classes_reducer'
import coursesReducer from './courses_reducer'

const reducers = combineReducers({
    auth: authReducer,
    user: userReducer,
    student: studentReducer,
    class: classesReducer,
    course: coursesReducer,
})

export default reducers;