import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from '../store/store'
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import Perfil from '../pages/Perfil'
import ViewUser from '../pages/ViewUser'
import UpdateUserPass from '../pages/UpdateUserPass';
import UpdatePerfil from '../pages/UpdatePerfil';
import UpdatePerfilPass from '../pages/UpdatePerfilPass';
import User from '../pages/User';
import AddUser from '../pages/AddUser'
import Student from '../pages/Student'
import CreateStudent from '../pages/Student/Add'
import baseLogin from '../containers/login';
import baseDashboard from '../containers/dashboard';

import CreateClass from '../pages/Class/Add'
import Class from '../pages/Class'
import PaymentsClass from '../pages/Class/Payments'

import Course from '../pages/Course'
import CreateCourse from '../pages/Course/Add'
import SubscribeCourse from '../pages/Course/Subscribe'
import SubscribeStudent from '../pages/Course/SubscribeStudent'


import RegistrationClass from '../pages/Class/Registration'

import Installments from '../pages/Class/Installments'
import ListSubscribes from '../pages/Course/ListSubscribes';
export default function Routes(){
    return(
        <Provider store={store}>
            <BrowserRouter >
                <Switch>
                    <Route path="/" exact component={baseLogin(Login)} />
                    <Route path="/dashboard" exact component={baseDashboard(Dashboard)} />
                    <Route path="/perfil" exact component={baseDashboard(Perfil)} />
                    <Route path="/user" exact component={baseDashboard(User)} />
                    <Route path="/students" exact component={baseDashboard(Student)} />
                    <Route path="/add-student" exact component={baseDashboard(CreateStudent)} />

                    <Route path="/payments/:id" exact component={baseDashboard(PaymentsClass)} />
                    <Route path="/registration/:id" exact component={baseDashboard(RegistrationClass)} />

                    <Route path="/class" exact component={baseDashboard(Class)} />
                    <Route path="/add-class" exact component={baseDashboard(CreateClass)} />
                    <Route path="/update-class/:id" exact component={baseDashboard(CreateClass)} />
                    <Route path="/installments/:id" exact component={baseDashboard(Installments)} />

                    <Route path="/update-student/:id" exact component={baseDashboard(CreateStudent)} />
                    <Route path="/view-user/:id" exact component={baseDashboard(ViewUser)} />
                    <Route path="/add-user" exact component={baseDashboard(AddUser)} />
                    <Route path="/update-user/:id" exact component={baseDashboard(AddUser)} />
                    <Route path="/update-user-pass/:id" exact component={baseDashboard(UpdateUserPass)} />
                    <Route path="/update-perfil" exact component={baseDashboard(UpdatePerfil)} />
                    <Route path="/update-perfil-pass" exact component={baseDashboard(UpdatePerfilPass)} />

                    <Route path="/course" exact component={baseDashboard(Course)} />
                    <Route path="/add-course" exact component={baseDashboard(CreateCourse)} />
                    <Route path="/update-course/:id" exact component={baseDashboard(CreateCourse)} />
                    <Route path="/course-subs/:id" exact component={baseDashboard(ListSubscribes)} />
                    <Route path="/course-sub-form" exact component={baseDashboard(SubscribeStudent)} />

                    <Route path="/inscrever" exact component={SubscribeCourse} />
                </Switch>
            </BrowserRouter>
        </Provider>
    )
} 