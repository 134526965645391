import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet'
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
  <React.Fragment>
    <Helmet>
          <title>Sistema - Chef Vilmar Turnes</title>
    </Helmet>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);
