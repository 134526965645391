import React, { useEffect, useState } from 'react';
import axios from   'axios';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { api } from '../../config'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/classes'
import { InputAdornment, Tabs, Tab, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, MenuItem, FormControl, InputLabel, Select, Button, Box, TextField } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment';

import { toast } from 'react-toastify';

const deparaStatus = {
    "PENDING": "Aguardando pagamento",
    "RECEIVED": "Recebida (saldo já creditado na conta)",
    "CONFIRMED": "Pagamento confirmado (saldo ainda não creditado)",
    "OVERDUE": "Vencida",
    "REFUNDED": "Estornada",
    "RECEIVED_IN_CASH": "Recebida em dinheiro (não gera saldo na conta)",
    "REFUND_REQUESTED": "Estorno Solicitado",
    "REFUND_IN_PROGRESS": "Estorno em processamento (liquidação já está agendada, cobrança será estornada após executar a liquidação)",
    "CHARGEBACK_REQUESTED": "Recebido chargeback",
    "CHARGEBACK_DISPUTE": "Em disputa de chargeback (caso sejam apresentados documentos para contestação)",
    "AWAITING_CHARGEBACK_REVERSAL": "Disputa vencida, aguardando repasse da adquirente",
    "DUNNING_REQUESTED": "Em processo de negativação",
    "DUNNING_RECEIVED": "Recuperada",
    "AWAITING_RISK_ANALYSIS": "Pagamento em análise"
};

const deparaBillingType = {
    "PIX": "Pix",
    "CREDIT_CARD": "Cartão de Crédito",
    "BOLETO": "Boleto",
    "UNDEFINED": "Definido pelo usuário"
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function getNextDay() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().slice(0, 10);
}

const Payments = (props) => {
    const initialState = () => ({
        billingType: "UNDEFINED",
        dueDate: getNextDay(),
        value: 0,
        description: "",
        fine: 0,
        interest: 0,
    });
    const [formValues, setFormValues] = useState(initialState());
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        props.getInstallments({ _id: props.match.params.id })
        props.getRandomPayment({ _id: props.match.params.id })
        props.getRegistration({ _id: props.match.params.id })
    }, []);

    const handleField = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };


    const generate = async () => {
        const res = await props.generateRandomPayment({ id: props.match.params.id, ...formValues })
        if (res.error?.error) {
            toast.error(res.error.message)
        } else {
            await props.getRandomPayment({ _id: props.match.params.id })
            toast.success(res.message)
            setFormValues(initialState())
            setOpen(false)
        }
    }

    return (
        <div className="p-4">
            <div className="d-flex">
                <div className="mr-auto">
                    <h2 className="titulo">{props.registration?.class.name} - {props.registration?.student.name}</h2>
                </div>
                <a href={`${api}/files/carnes/${props.registration?._id}.pdf`} target='_blank'><Button className="btn btn-custom-1 btn-sm mr-2">Carnê</Button></a>
                <Button onClick={() => setOpen(true)} className="btn btn-custom-1 btn-sm">Cobrança Avulsa</Button>
            </div>
            <Box sx={{ width: "100%" }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="Mensalidade" />
                    <Tab label="Avulso" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Número</TableCell>
                                <TableCell>Vencimento</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Data Pagamento</TableCell>
                                <TableCell align='center'>Fatura</TableCell>
                                <TableCell align='center'>Boleto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.installments?.map(row => (
                                <TableRow
                                    key={row.installmentNumber}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell><b>{row?.installmentNumber}</b></TableCell>
                                    <TableCell>{moment(row?.originalDueDate).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>R${row?.value}</TableCell>
                                    <TableCell><b>{deparaStatus[row?.status]}</b></TableCell>
                                    <TableCell>{row?.paymentDate ? moment(row?.paymentDate).format('DD/MM/YYYY') : "-"}</TableCell>
                                    <TableCell align='center'><a href={row?.invoiceUrl} target="_blank"><Tooltip title="Fatura"><Icon width="25" icon="mdi:invoice" /></ Tooltip></a></TableCell>
                                    <TableCell align='center'><a href={row?.bankSlipUrl} target="_blank"><Tooltip title="Boleto"><Icon width="25" icon="mdi:bank" /></ Tooltip> </a></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Criado</TableCell>
                                <TableCell>Vencimento</TableCell>
                                <TableCell>Valor</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Método</TableCell>
                                <TableCell>Data Pagamento</TableCell>
                                <TableCell align='center'>Fatura</TableCell>
                                <TableCell align='center'>Boleto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.random_payment?.map(row => (
                                <TableRow
                                    key={row.nossoNumero}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell><b>{row?.description}</b></TableCell>

                                    <TableCell>{moment().format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{moment(row?.originalDueDate).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>R${row?.value}</TableCell>
                                    <TableCell><b>{deparaStatus[row?.status]}</b></TableCell>
                                    <TableCell>{deparaBillingType[row?.billingType]}</TableCell>
                                    <TableCell>{row?.paymentDate ? moment(row?.paymentDate).format('DD/MM/YYYY') : "-"}</TableCell>
                                    <TableCell align='center'><a href={row?.invoiceUrl} target="_blank"><Tooltip title="Fatura"><Icon width="25" icon="mdi:invoice" /></ Tooltip></a></TableCell>
                                    <TableCell align='center'>{row?.billingType === 'BOLETO' ? <a href={row?.bankSlipUrl} target="_blank"><Tooltip title="Boleto"><Icon width="25" icon="mdi:bank" /></ Tooltip> </a> : '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <Modal isOpen={open} >
                <ModalHeader toggle={() => setOpen(false)}>Cobrança Avulsa</ModalHeader>

                <ModalBody>
                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                        <InputLabel>Tipo de Cobrança</InputLabel>
                        <Select
                            label='Tipo de Cobrança'
                            name="billingType"
                            onChange={handleField}
                            value={formValues.billingType}
                        >
                            <MenuItem value="UNDEFINED">Perguntar ao cliente</MenuItem>
                            <MenuItem value="BOLETO">Boleto Bancário</MenuItem>
                            <MenuItem value="CREDIT_CARD">Cartão de Crédito</MenuItem>
                            <MenuItem value="PIX">PIX</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        name="dueDate"
                        label="Data de Vencimento"
                        onChange={handleField}
                        type="date"
                        value={formValues.dueDate}
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        name="value"
                        onChange={handleField}
                        label="Valor Total"
                        type="number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    R$
                                </InputAdornment>
                            ),
                        }}
                        value={formValues.value}
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        name="description"
                        onChange={handleField}
                        label="Descrição"
                        value={formValues.description}
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        name="discount"
                        onChange={handleField}
                        label="Desconto"
                        type="number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    R$
                                </InputAdornment>
                            ),
                        }}
                        value={formValues.discount}
                        size="small"
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        name="fine"
                        onChange={handleField}
                        label="Multa"
                        type="number"
                        value={formValues.fine}
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        name="interest"
                        onChange={handleField}
                        label="Juros"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        type="number"
                        value={formValues.interest}
                        size="small"
                        fullWidth
                    />
                </ModalBody>
                <ModalFooter>
                    <Button style={{ marginRight: '15px' }} size="sm" onClick={generate} variant='contained'>Gerar</Button>
                    <Button size="sm" color="secondary" onClick={() => setOpen(false)}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>

    )
}


const mapStateToProps = state => ({
    installments: state.class.installments,
    registration: state.class.registration,
    random_payment: state.class.random_payment
})

export default connect(mapStateToProps, actions)(Payments);

