import React from 'react';
import {Button, Spinner} from 'reactstrap';

const SpinnerLogin = (props) => {
    if(props.loading) return(
        <Button type="button" className="btn btn-lg btn-block" disabled >Aguarde... <Spinner className="ml-1" size="sm" color="light"/></Button>
    )

    return (
        <Button type="button" className="btn btn-lg btn-block">Entrar</Button>
    )
}

export default SpinnerLogin;