import React from 'react'
import Routes from './routes/routes';
import { ToastContainer } from 'react-toastify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';

import 'react-toastify/dist/ReactToastify.css';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#783030',
      dark: '#351414',
      darker: '#ba7272',
      contrastText: '#fff',
      lighter: '#edc636',
      light: '#eabf1d',
    },
    secondary: {
      lighter: '#ba7272',
      light: '#84A9FF',
      main: '#ba7272',
      dark: '#ba7272',
      darker: '#091A7A',
      contrastText: '#fff'
    },
    default: {
      main: "#FFF"
    },
    action: {
      hover: "#ba7272",
      disabled: "#783030",
      disabledBackground: "#783030",
      focus: "#783030",
      hoverOpacity: 0.08,
      disabledOpacity: 0.48
    }
  },
});
function App() {
  return <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
      <Routes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </LocalizationProvider>
  </ThemeProvider>
}

export default App;
