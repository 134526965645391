import React from 'react';
import BaseDashboard from './BaseDashboard'

import { connect } from 'react-redux';
import * as actions from '../../store/actions'

const baseDashboard = (Component, permissions = null, platform = null) => {
    class ComponentBaseDashboard extends React.Component {
        componentDidMount() {
            const { getUser } = this.props;
            getUser();
        }

        componentDidUpdate() {
            const { history, authorized, user } = this.props;
            if (!authorized) return history.replace("/")
        }

        render() {
            return (
                <BaseDashboard>
                    <Component {...this.props} />
                </BaseDashboard>
            )
        }
    }

    const mapStateToProps = state => ({
        authorized: state.auth.authorized,
        user: state.auth.user
    })

    return connect(mapStateToProps, actions)(ComponentBaseDashboard)
}

export default baseDashboard;