import axios from 'axios';
import {api} from '../../config'
import {getToken, saveToken, getHeaders, clearToken} from './localStorage'
import errorHandling from './errorHandling'

export const handleLogin = (props, callback) => {
    return function(dispatch){
        axios.post(api+"/login", props).then((response) => {
            saveToken(response.data);
            dispatch({type: "LOGIN_USER", payload: response.data})
        }).catch((err) => callback(errorHandling(err)))
    }
}

export const getUser = () => {
    return function(dispatch){
        if(getToken()){
            axios.get(api+"/perfil", getHeaders()).then((response) => {
                saveToken(response.data);
                dispatch({type: "LOGIN_USER", payload: response.data})
            }).catch((err) => {
                clearToken()
                dispatch({type: "LOGOUT_USER"})
            })
        }else{
            clearToken()
            dispatch({type: "LOGOUT_USER"})
        }
    }
}

export const getInfoDashboard = (callback) => {
    return function(dispatch){
        axios.get(api+"/info-dashboard", getHeaders()).then((response) => {
            callback({data: response.data});
        }).catch(() => {
            
        })
    }
}

export const handleLogout = () => {
    clearToken()
    return {type: "LOGOUT_USER"}
}