const users_reduce = (state = {}, actions) => {
    switch(actions.type){
        case "GET_USERS":
            return{
                ...state,
                users: actions.payload.users
            }
        case "GET_USER":
            return{
                ...state,
                userDetails: actions.payload.user
            }
        case "CLEAR_USER":
            return{
                ...state,
                userDetails: null
            }
        case "CLEAR_USERS":
            return{
                ...state,
                users: null
            }
        default: 
            return state;
    }
}

export default users_reduce;