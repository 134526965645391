const classes_reduce = (state = {}, actions) => {
    switch (actions.type) {
        case "GET_CLASSES":
            return {
                ...state,
                classes: actions.payload.data
            }
        case "GET_CLASS":
            return {
                ...state,
                classDetails: actions.payload.data
            }
        case "GET_REGISTRATIONS":
            return {
                ...state,
                registrations: actions.payload.data
            }
        case "GET_REGISTRATION":
            return {
                ...state,
                registration: actions.payload.data
            }
        case "GET_INSTALLMENTS":
            return {
                ...state,
                installments: actions.payload.data
            }
        case "GET_INSTALLMENT":
            return {
                ...state,
                installment: actions.payload.data
            }
        case "GET_RANDOM_PAYMENTS":
            return {
                ...state,
                random_payment: actions.payload.data
            }
        case "CLEAR_CLASS":
            return {
                ...state,
                classDetails: null
            }
        case "CLEAR_CLASSES":
            return {
                ...state,
                classes: null
            }
        default:
            return state;
    }
}

export default classes_reduce;