import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux'
import * as actions from '../../store/actions/users'
import SpinnerDelete from '../../components/SpinnerDelete'
import DetailsUser from './DetailsUser'
import AlertDanger from '../../components/AlertDanger'
import AlertSuccess from '../../components/AlertSuccess'
import { UncontrolledButtonDropdown,  DropdownToggle,DropdownMenu,DropdownItem } from 'reactstrap';


class ViewUser extends Component{
    state = {
        user: "",
        msg: "",
        error: "",
        success: "",
        loading: false,
        formSuccess: false
    }

    componentDidMount(){
        const {id} = this.props.match.params
        this.getViewUser(id);
    }

    componentDidUpdate(nextProps){
        const {id} = this.props.match.params
        if(!this.props.user && nextProps.user) this.props.getViewUser(id)
    }

    componentWillUnmount(){
        this.props.clearUser()
    }

    getViewUser(id){        
        this.props.getViewUser(id)
    }

    deleteUser(_id){
        this.setState({loading: true});
        this.props.deleteUser(_id, (msg) => {
            if(msg.error.error){
                this.setState({error: {message: msg.error.message}})
            }else{
                this.setState({success: {message: msg.error.message}})
                this.setState({formSuccess: true});
            }
            this.setState({loading: false});
        })
    }
    
    render(){
        const {error, success, loading, formSuccess} = this.state

        if(formSuccess){
            return <Redirect to={{
                pathname: '/user',
                state: {msg: "Usuário apagado com sucesso!"}
            }} />
        } 

        return (
            <>
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Visualizar Usuário</h2>
                </div>
                <span className="d-none d-md-block">
                    <Link to={"/user"}>
                        <button className="btn btn-info btn-sm">Listar</button>
                    </Link>
                    <Link to={"/update-user/" + this.props.match.params.id}>
                        <button className="ml-1 mr-1 btn btn-warning btn-sm">Editar</button>
                    </Link>
                    <Link to={"/update-user-pass/" + this.props.match.params.id}>
                        <button className="mr-1 btn btn-warning btn-sm">Editar Senha</button>
                    </Link>
                    <span onClick={() => this.deleteUser(this.props.match.params.id)}>
                        <SpinnerDelete loading={loading} />
                    </span>
                </span>
                <div className="dropdown d-block d-md-none">
                    <UncontrolledButtonDropdown >
                        <DropdownToggle color="primary" size="sm" caret>
                            Ações
                        </DropdownToggle>
                        <DropdownMenu right>
                            <Link className="dropdown-item" to={"/user"}> Listar</Link>
                            <Link className="dropdown-item" to={"/update-user/" + this.props.match.params.id}> Editar</Link>
                            <Link className="dropdown-item" to={"/update-user-pass/" + this.props.match.params.id}> Editar Senha</Link>
                            <DropdownItem onClick={() => this.deleteUser(this.props.match.params.id)}>Apagar</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </div><hr />
            <AlertDanger error={error}/>
            <AlertSuccess error={success}/>
            <DetailsUser></DetailsUser>
            </>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user.user
})

export default connect(mapStateToProps, actions)(ViewUser);