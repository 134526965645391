import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { getCourses, handleAddCourseStudent, getCoursesSubscribed } from '../../store/actions/courses'
import { getStudentByCPF, handleAddStudent } from "../../store/actions/students";
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField, Select, FormControl, InputLabel, Autocomplete, Container, MenuItem, Alert } from '@mui/material';

const FormSub = (props) => {
    const [courseExist, setCourse] = useState({});
    const [courses, setCourses] = useState([]);
    const [student, setStudent] = useState({});
    const [messageSuccess, setMessageSuccess] = useState('');
    const [discount, setDiscount] = useState(0);
    const NewCourseSchema = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório.'),
        cpfCnpj: Yup.string().required('CPF ou CNPJ é obrigatório.'),
        email: Yup.string().email('E-mail inválido.').required('E-mail é obrigatório.'),
        mobilePhone: Yup.string().required('Celular é obrigatório.'),
    });

    useEffect(() => {
        props.getCourses({ pageCurrent: 1, limit: 99999, origin: 'form' }).then(e => {
        })
    }, []);

    useEffect(() => {
        setCourses(props.courses?.docs || []);
    }, [props.courses]);

    useEffect(() => {
        const coursesFilter = courses.filter(e => {
            return !props.courses_subscribed?.find(e2 => e2.course._id === e._id)
        })
        setCourses(coursesFilter || []);
    }, [props.courses_subscribed]);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            cpfCnpj: '',
            name: '',
            email: '',
            mobilePhone: '',
            courses: [],
            paymentMethod: 1,
            payment: true
        },
        validationSchema: NewCourseSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            try {
                let result = {};
                let id = student?._id;
                if (!id) {
                    const newStudent = await props.addStudent(values);
                    id = newStudent.id;

                    if (newStudent.error) {
                        setSubmitting(false);
                        toast.error(newStudent.error.message);
                        return;
                    }
                }
                result = await props.addCourseStudent({ courses: values.courses.map(e => e._id), student: id, installments: values.paymentMethod, payment: values.payment ? 'true' : 'false', codeDiscount: values.codeDiscount });
                if (result.error) {
                    setSubmitting(false);
                    toast.error(result.error.message);
                } else {
                    if (props.admin) {
                        if (values.payment) {
                            setMessageSuccess(<><strong>Inscrição realizada com sucesso!</strong> Visualize a fatura clicando <a href={result.link} target="_blank">aqui</a>.</>)
                        } else {
                            setMessageSuccess(<><strong>Inscrição realizada com sucesso!</strong>.</>)

                        }
                    } else {
                        setMessageSuccess(<><strong>Inscrição realizada com sucesso!</strong> Efetue o pagamento clicando <a href={result.link} target="_blank">aqui</a>.</>)
                    }
                    resetForm();
                    setSubmitting(false);
                }
            } catch (error) {
                setSubmitting(false);
                toast.error(error.message);
            }
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

    useEffect(() => {
        // sempre maisculo
        setFieldValue('codeDiscount', values.codeDiscount?.toUpperCase());
        if (values.codeDiscount === '10HIPPO') {
            setDiscount(10);
        } else {
            setDiscount(0);
        }
    }, [values.codeDiscount]);

    useEffect(() => {
        let valueCpfCnpj = values.cpfCnpj?.replace(/\D/g, '')?.substring(0, 11);
        setFieldValue('cpfCnpj', valueCpfCnpj);
        if (valueCpfCnpj.length === 11) {
            props.getStudentByCPF(valueCpfCnpj);
        } else {
            setStudent({});
            setCourse([]);
        }
    }, [values.cpfCnpj]);


    useEffect(() => {
        if (props.student) {
            setFieldValue('name', props.student.name);
            setFieldValue('email', props.student.email);
            setFieldValue('mobilePhone', props.student.mobilePhone);

            props.getCoursesSubscribed(props.student._id);
        }
        setStudent(props.student);
    }, [props.student]);

    useEffect(() => {
        if (props.courses_subscribed?.length > 0) {
            setCourse(props.courses_subscribed);
        } else {
            setCourse([]);
        }
    }, [props.courses_subscribed]);

    const valorTotal = values.courses?.reduce((acc, cur) => {
        return acc + cur.value;
    }, 0);
    const valorComDesconto = valorTotal - (valorTotal * discount / 100);
    return (
        <Container maxWidth="md">
            <div className="p-4">
                {!props.admin &&
                    <div style={{ textAlign: 'center' }}>
                        <div className="mr-auto p-2">
                            <h2 className="titulo">Cursos - Chef Vilmar Turnes</h2>
                            <small>www.vilmarturnes.com.br</small>
                        </div>
                    </div>
                }
                {messageSuccess &&
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {messageSuccess}
                    </Alert>
                }
                <FormikProvider value={formik}>
                    <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card sx={{ p: 3 }}>
                                    <Stack spacing={3}>
                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                            <TextField
                                                fullWidth
                                                label={<div>CPF ou CNPJ <small>(Somente números)</small></div>}
                                                {...getFieldProps('cpfCnpj')}
                                                error={Boolean(touched.cpfCnpj && errors.cpfCnpj)}
                                                helperText={touched.cpfCnpj && errors.cpfCnpj}
                                                name="cpfCnpj"
                                                type="text"
                                                placeholder="Digite o CPF"
                                            />
                                            <TextField
                                                fullWidth
                                                disabled={!!student?._id}
                                                label="Nome"
                                                {...getFieldProps('name')}
                                                error={Boolean(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                name="name"
                                                type="text"
                                                placeholder="Digite o nome"
                                            />
                                        </Stack>
                                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                                            <TextField
                                                fullWidth
                                                disabled={!!student?._id}
                                                label="E-mail"
                                                {...getFieldProps('email')}
                                                error={Boolean(touched.email && errors.email)}
                                                helperText={touched.email && errors.email}
                                                name="email"
                                                type="email"
                                                placeholder="Digite o e-mail"
                                            />
                                            <TextField
                                                fullWidth
                                                disabled={!!student?._id}
                                                label="Celular"
                                                {...getFieldProps('mobilePhone')}
                                                error={Boolean(touched.mobilePhone && errors.mobilePhone)}
                                                helperText={touched.mobilePhone && errors.mobilePhone}
                                                name="mobilePhone"
                                                type="text"
                                                placeholder="Digite o telefone"
                                            />
                                        </Stack>
                                        {props.admin &&
                                            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                                <InputLabel>Gerar pagamento</InputLabel>
                                                <Select
                                                    fullWidth
                                                    label="Gerar pagamento"
                                                    {...getFieldProps('payment')}
                                                    error={Boolean(touched.payment && errors.payment)}
                                                    helperText={touched.payment && errors.payment}
                                                    name="payment"
                                                >
                                                    <MenuItem value={true}>Sim</MenuItem>
                                                    <MenuItem value={false}>Não</MenuItem>
                                                </Select>
                                            </FormControl>
                                        }
                                        <Autocomplete
                                            multiple
                                            options={courses || []}
                                            {...getFieldProps('courses')}
                                            onChange={(event, newValue) => {
                                                setFieldValue('courses', newValue);
                                            }}
                                            getOptionLabel={(option) => {
                                                let date = new Date(option.start);
                                                date = date.toLocaleDateString('pt-BR');
                                                return date + ' - ' + option.name + ' - R$' + option.value?.toFixed(2)?.replace('.', ',')
                                            }}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} label="Cursos" />}
                                        />
                                        <TextField
                                            fullWidth
                                            disabled={!!student?._id}
                                            label="Cupom de desconto"
                                            {...getFieldProps('codeDiscount')}
                                            error={Boolean(touched.codeDiscount && errors.codeDiscount)}
                                            helperText={touched.codeDiscount && errors.codeDiscount}
                                            name="codeDiscount"
                                            type="text"
                                            placeholder="Digite o cupom de desconto"
                                        />
                                        {(values.courses?.reduce((acc, cur) => {
                                            return acc + cur.value;
                                        }, 0) > 1000 && values.payment) &&
                                            <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                                <InputLabel>Forma de pagamento</InputLabel>
                                                <Select
                                                    fullWidth
                                                    label="Forma de pagamento"
                                                    {...getFieldProps('paymentMethod')}
                                                    error={Boolean(touched.paymentMethod && errors.paymentMethod)}
                                                    helperText={touched.paymentMethod && errors.paymentMethod}
                                                    name="paymentMethod"
                                                >
                                                    <MenuItem value={1}>À vista</MenuItem>
                                                    <MenuItem value={2}>2x sem juros</MenuItem>
                                                </Select>
                                            </FormControl>
                                        }
                                        {
                                            discount > 0 && <small style={{ color: 'green', fontWeight: 'bold' }}>Desconto de {discount}% aplicado!</small>
                                        }
                                        <p>Valor total: R${
                                            (valorComDesconto)?.toFixed(2)?.replace('.', ',')
                                        }</p>
                                        {courseExist?.length > 0 &&
                                            <div>
                                                <h5>Cursos já inscritos</h5>
                                                <ul>
                                                    {courseExist?.map(e => {
                                                        return <li key={e._id}>{new Date(e.course.start)?.toLocaleDateString('pt-BR')} - {e.course.name}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                        <Box sx={{ mt: 3 }}>
                                            <LoadingButton fullWidth disabled={values.cpfCnpj?.length < 11} type="submit" style={{ textTransform: 'initial' }} loading={isSubmitting}>Realizar Inscrição</LoadingButton>
                                        </Box>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </Form>
                </FormikProvider >
            </div>
        </Container>
    )
}



const mapStateToProps = state => {
    return ({
        courses: state.course.courses,
        student: state.student.studentDetails,
        courses_subscribed: state.course.courses_subscribed
    })
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCourses: (data) => dispatch(getCourses(data)),
        getStudentByCPF: (cpf) => dispatch(getStudentByCPF(cpf)),
        addStudent: (data) => dispatch(handleAddStudent(data)),
        addCourseStudent: (data) => dispatch(handleAddCourseStudent(data)),
        getCoursesSubscribed: (id) => dispatch(getCoursesSubscribed(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSub);