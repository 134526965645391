import React from 'react';
import FormSub from '../../components/Course/FormSub';

const SubscribeStudent = () => {
  return (
    <FormSub admin={true}/>
  );
};

export default SubscribeStudent;
