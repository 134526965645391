import { useEffect, useReducer, useState } from "react";
import { connect } from 'react-redux'
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { getViewCourse, handleAddCourse, handleUpdateCourse } from '../../store/actions/courses'
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack, TextField, Select, FormControl, InputLabel, Autocomplete } from '@mui/material';

const AddCourse = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [courseExist, setCourse] = useState({});
  const NewCourseSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório.'),
    start: Yup.string().required('Data de início é obrigatório.'),
    value: Yup.string().required('Valor é obrigatório.')
  });

  useEffect(() => {
    if (props.match.params.id) {
      setIsEdit(true);
      props.getViewCourse(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.match.params.id) {
      setCourse(props.course);
    }
  }, [props.course]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: courseExist?.name || '',
      start: courseExist?.start?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      end: courseExist?.end?.slice(0, 10) || false,
      value: courseExist?.value || '',
    },
    validationSchema: NewCourseSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        let valuesApi = { ...values };
        const start = new Date(values.start);
        start.setHours(start.getHours() + 3);
        valuesApi.start = start.toISOString();
        if (!values.end) {
          delete valuesApi.end
        } else {
          const end = new Date(values.end);
          end.setHours(end.getHours() + 3);
          valuesApi.end = end.toISOString();
        }
        if (isEdit) {
          valuesApi._id = props.match.params.id;
        }
        const result = isEdit ? await props.updateCourse(valuesApi) : await props.addCourse(valuesApi);
        if (result.error) {
          setSubmitting(false);
          toast.error(result.error.message);
        } else {
          if (!isEdit) resetForm();
          toast.success(result.message);
          setSubmitting(false);
        }
      } catch (error) {
        setSubmitting(false);
        toast.error(error.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <div className="p-4">
      <div className="d-flex">
        <div className="mr-auto p-2">
          <h2 className="titulo">{isEdit ? "Editar" : "Cadastrar"} Curso</h2>
        </div>
        <Link to={props.match.params.role ? `/course/${props.match.params.role}/${props.match.params.store}` : "/course"}>
          <div className="p-2">

            <button className="btn btn-outline-info btn-sm">
              Listar
            </button>
          </div>
        </Link>
      </div>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      label={'Nome'}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="date"
                      label={'Data de início'}
                      {...getFieldProps('start')}
                      error={Boolean(touched.start && errors.start)}
                      helperText={touched.start && errors.start}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="date"
                      label={'Data de fim'}
                      {...getFieldProps('end')}
                      error={Boolean(touched.end && errors.end)}
                      helperText={touched.end && errors.end}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                      fullWidth
                      type="number"
                      label={'Valor'}
                      {...getFieldProps('value')}
                      error={Boolean(touched.value && errors.value)}
                      helperText={touched.value && errors.value}
                    />
                  </Stack>
                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>Salvar</LoadingButton>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider >
    </div>
  )
}



const mapStateToProps = state => {
  return ({
    course: state.course.courseDetails
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCourse: (data) => dispatch(handleAddCourse(data)),
    getViewCourse: (id) => dispatch(getViewCourse(id)),
    updateCourse: (data) => dispatch(handleUpdateCourse(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCourse);