import React from 'react';
import { Link } from 'react-router-dom'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SchoolIcon from '@mui/icons-material/School';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ChecklistIcon from '@mui/icons-material/Checklist';

import { MenuItem, ListItemIcon, ListItemText, MenuList, Typography } from '@mui/material';


const MenuLink = ({ to, children, Icon }) => {
    return (
        <MenuItem className="li" component={Link} to={to}>
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText disableTypography={true} sx={{ fontSize: '14px' }} primary={children} />
        </MenuItem>
    );
};

const Sidebar = (props) => {
    return (
        <nav className={props.active ? "sidebar" : "sidebar toggled"}>
            <MenuList className="ul">
                <MenuLink to="/dashboard" Icon={GridViewOutlinedIcon}>Dashboard</MenuLink>

                <MenuLink to="/students" Icon={GroupOutlinedIcon}>Alunos</MenuLink>
                <details open>
                    <summary aria-controls="admin-content" id="admin-header">
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>Administração</Typography>
                    </summary>
                    <div>
                        <MenuLink to="/user" Icon={GroupOutlinedIcon}>Usuários</MenuLink>
                    </div>
                </details>
                <details open>
                    <summary aria-controls="school-content" id="school-header">
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>Escola</Typography>
                    </summary>
                    <div>
                        <MenuLink to="/class" Icon={SchoolIcon}>Turmas</MenuLink>
                    </div>
                </details>
                <details open>
                    <summary aria-controls="workshop-content" id="workshop-header">
                        <Typography variant="h6" sx={{ color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>Workshops</Typography>
                    </summary>
                    <div>
                        <MenuLink to="/course-sub-form" Icon={ChecklistIcon}>Inscrever</MenuLink>
                        <MenuLink to="/course" Icon={RedoIcon}>Próximos</MenuLink>
                        <MenuLink to="/course?query=prev" Icon={UndoIcon}>Anteriores</MenuLink>
                    </div>
                </details>
            </MenuList>
        </nav>
    )
}


export default Sidebar;