import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SpinnerDelete from '../../components/SpinnerDelete'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/courses'
import { Pagination, Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, MenuItem, Menu } from '@mui/material';
import { Icon } from '@iconify/react';
import moment from 'moment';
import { getStatus } from '../../utils/Asaas';


import { toast } from 'react-toastify';

const ListSubscribes = (props) => {
    const [pageCurrent, setPageCurrent] = useState(1);
    const limit = 20;
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [idDelete, setIdDelete] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selected, setSelected] = useState(null);

    const handleMenuClick = (event, id) => {
        setSelected(id)
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        props.getViewCourse(props.match.params.id);
    }, []);

    useEffect(() => {
        props.getSubs({ pageCurrent, limit, id: props.match.params.id })
    }, [pageCurrent]);

    const deleteCourse = async () => {
        setLoading(true)
        const result = await props.updateSubsCourse({
            id: idDelete,
            status: 'i'
        })
        await props.getSubs({ pageCurrent, limit, id: props.match.params.id })
        toast.success('Inscrição cancelada com sucesso!');
        setLoading(false)
        toggleModal();
    }

    const printTable = () => {
        let printContent = document.getElementById('table-container').innerHTML;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = printContent;

        const lastColumn = tempDiv.querySelectorAll('.actions');
        if (lastColumn.length > 0) {
            lastColumn.forEach((column) => {
                column.parentNode.removeChild(column);
            });
        }
        printContent = tempDiv.innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
    };


    const update = async (id, obj) => {
        await props.updateSubsCourse({
            id,
            ...obj
        })
        await props.getSubs({ pageCurrent, limit, id: props.match.params.id })
    }

    const toggleModal = (_id = "") => {
        if (_id) {
            setIdDelete(_id)
        } else {
            setIdDelete("")
        }
        setOpenModal(!openModal);
    }

    let subs = [];

    if (props.subs) {
        subs = props.subs.docs
    }

    return (
        <div className="p-4">
            <div className="d-flex">
                <div className="mr-auto p-2">
                    <h2 className="titulo">Workshop - {props.course?.name}</h2>
                </div>
                <Button style={{ marginRight: '20px', height: '30px' }} className="btn btn-custom-1 btn-sm" onClick={() => printTable()}>Imprimir</Button>
            </div>
            <TableContainer id="table-container" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Inscrição</TableCell>
                            <TableCell>Nome</TableCell>
                            <TableCell>E-mail</TableCell>
                            <TableCell>Telefone</TableCell>
                            <TableCell>Pagamento</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell className='actions'>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subs.map(row => (
                            <TableRow
                                key={row._id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{moment(row.createdAt).format("DD/MM/YYYY")}</TableCell>
                                <TableCell><b>{row.student.name}</b></TableCell>
                                <TableCell>{row.student.email}</TableCell>
                                <TableCell>{row.student.mobilePhone}</TableCell>

                                <TableCell style={{ textTransform: 'capitalize' }}>{getStatus(row.statusPayment)}</TableCell>
                                <TableCell style={{ textTransform: 'capitalize' }}>{(row.statusPresence)}</TableCell>
                                <TableCell className='actions'>
                                    <Button
                                        size="small"
                                        onClick={(e) => handleMenuClick(e, row._id)}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Ações
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => toggleModal(selected)}>Cancelar</MenuItem>
                                        <MenuItem onClick={() => update(selected, { statusPayment: 'pago' })}>Pago</MenuItem>
                                        <MenuItem onClick={() => update(selected, { statusPayment: 'não pago' })}>Não Pago</MenuItem>
                                        <MenuItem onClick={() => update(selected, { statusPresence: 'presente' })}>Presente</MenuItem>
                                        <MenuItem onClick={() => update(selected, { statusPresence: 'ausente' })}>Ausente</MenuItem>
                                        <MenuItem onClick={() => update(selected, { statusPresence: 'confirmado' })}>Confirmado</MenuItem>
                                        <MenuItem onClick={() => update(selected, { statusPayment: 'isento' })}>Isentar</MenuItem>
                                        {row.urlPaymnent && (
                                            <MenuItem onClick={() => window.open(row.urlPaymnent, '_blank')}>
                                                Visualizar Fatura
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            {
                !props.subs ? "" :
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Pagination showFirstButton showLastButton variant="outlined" shape="rounded" count={props.subs?.totalPages} page={pageCurrent} onChange={(e, page) => setPageCurrent(page)} />
                    </Box>
            }

            <Modal isOpen={openModal} >
                <ModalHeader toggle={() => toggleModal()}>Cancelar</ModalHeader>
                <ModalBody>
                    Você tem certeza que deseja cancelar a inscrição?
                </ModalBody>
                <ModalFooter>
                    <span onClick={() => deleteCourse()}>
                        <SpinnerDelete text={true} loading={loading} />
                    </span>
                    <Button size="sm" color="secondary" onClick={() => toggleModal()}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div >
    )
}


const mapStateToProps = state => ({
    course: state.course.courseDetails,
    subs: state.course.subs
})

export default connect(mapStateToProps, actions)(ListSubscribes);

