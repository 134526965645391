import React, { useState, useEffect } from "react";
import { Button, TextField, List, ListItem, ListItemText } from "@mui/material";

const SearchAndEnroll = ({ students, enrollStudent, unenrollStudent }) => {
  const [search, setSearch] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    setFilteredStudents(
      students.filter((student) =>
        student.name.toLowerCase().includes(search.toLowerCase())
      )
    );
  }, [search, students]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleEnroll = (student) => {
    if (student.enrolled) {
      unenrollStudent(student._id);
    } else {
      enrollStudent(student._id);
    }
  };

  return (
    <div>
      <TextField
        label="Pesquisar aluno"
        variant="outlined"
        value={search}
        onChange={handleSearch}
        fullWidth
      />
      <List>
        {filteredStudents.map((student) => (
          <ListItem key={student._id}>
            <ListItemText primary={student.name} />
            <Button
              variant="contained"
              color={student.enrolled ? "secondary" : "primary"}
              onClick={() => handleEnroll(student)}
            >
              {student.enrolled ? "Desmatricular" : "Matricular"}
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SearchAndEnroll;
