const courses_reduce = (state = {}, actions) => {
    switch (actions.type) {
        case "GET_COURSES":
            return {
                ...state,
                courses: actions.payload.data
            }
        case "GET_COURSE":
            return {
                ...state,
                courseDetails: actions.payload.data
            }
        case "GET_RANDOM_PAYMENTS":
            return {
                ...state,
                random_payment: actions.payload.data
            }
        case "GET_COURSES_SUBSCRIBED":
            return {
                ...state,
                courses_subscribed: actions.payload.data
            }
        case "GET_SUBS":
            return {
                ...state,
                subs: actions.payload.data
            }
        case "CLEAR_COURSE":
            return {
                ...state,
                courseDetails: null
            }
        case "CLEAR_COURSES":
            return {
                ...state,
                coursees: null
            }
        default:
            return state;
    }
}

export default courses_reduce;