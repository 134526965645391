import React from 'react';
import {Spinner} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
library.add(fas)

const SpinnerDelete = (props) => {
    if(props.loading) return(
        <button className="btn btn-danger btn-sm" disabled >{props.text ? 'Processando...' : ''}{ <Spinner className="ml-1" size="sm" color="danger"/> }</button>
    )

    return (
        <button className="btn btn-danger btn-sm" >{props.text ? 'Apagar' : <FontAwesomeIcon icon="trash" />}</button>
    )
}

export default SpinnerDelete;